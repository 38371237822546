import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { Account } from '../../auth/models/account.model';
import { LoginService } from '../../auth/services/login.service';
import { CompanyService } from '../../services/company.service';
import { ErrorService } from '../../services/error.service';

@Injectable()
export class InitPathResolver {

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private errorService: ErrorService,
    private loginService: LoginService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<Account> {

    const companyId = parseInt(route.parent.paramMap.get('companyId'));

    this.companyService.checkCurrent();

    return this.loginService.getAccountsForCompany(companyId).pipe(
      take(1),
      map((accounts: Account[]) => {
        const account = accounts.find(a => a.company.id === companyId);

        if (account) {
          const { company } = account;

          const navigateTo = (section: string, queryParams?: any) => {
            this.router.navigate(['/company', companyId, section], {
              queryParams: queryParams
            });
          };

          if (company.hasModule('slots')) {
            navigateTo('slots', {
              order_by: '-date',
              "filters[date]": `between:today,45`,
              unassigned: true
            });
          } else {
            if (company.hasModule('fintech')) {
              navigateTo('fintech');
            } else {
              navigateTo('setup');
            }
          }
        }

        return null;
      }),
      catchError((error, caught) => {
        this.errorService.handleError(error);
        return EMPTY;
      })
    );
  }
}
